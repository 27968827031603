import React, { useEffect, useState } from 'react';
import { func, shape } from 'prop-types';
import { withCookies } from 'react-cookie';
import axios from 'axios';

import SignInPage from './SignInPage';


function SignInPageContainer(props) {
  const { onUserLoggedIn, cookies, onFailure } = props;

  const [error, setError] = useState(null);

  useEffect(() => {
    const ssoCookie = cookies.get('ia-sso-cookie');

    if (ssoCookie) {
      const requestUrl = `/api/auth/login-check`;

      axios
        .post(requestUrl, {}, {
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'x-auth-token': ssoCookie
          },
          withCredentials: true
        })
        .then(() => onUserLoggedIn());
    }
  }, []);

  useEffect(() => {
    if (error) {
      onFailure(error);
    }
    return () => {
      setError(null);
    };
  }, [error]);

  const onForgotPasswordClick = e => {
    e.preventDefault();
    window.alert('Please use your Google account or contact administration!');
  };

  return (
    <SignInPage onFailure={setError}
                onUserLoggedIn={onUserLoggedIn}
                onForgotPassword={onForgotPasswordClick}
    />
  );
}

SignInPageContainer.propTypes = {
  onUserLoggedIn: func.isRequired,
  onFailure: func.isRequired,
  cookies: shape({
    get: func.isRequired,
    remove: func.isRequired,
  }).isRequired
};

export default withCookies(SignInPageContainer);
