import React from 'react';
import { func } from 'prop-types';
import { styled } from '@mui/material';

import { Button } from '@intelliarts/react-components';
import GoogleIcon from './icons/googleIcon.svg?react';

import { getGoogleLogin } from './googleLoginButtonUtils';

const StyledGoogleIcon = styled(GoogleIcon)({
  marginRight: 15,
  marginTop: 5,
  marginBottom: 5
});


export default function GoogleLoginButton({ onFailure, onUserLoggedIn }) {
  const googleLogin = getGoogleLogin(onFailure, onUserLoggedIn);
  return (
    <Button
      startIcon={<StyledGoogleIcon/>}
      variant="outlined"
      size="large"
      onClick={googleLogin}
    >
      Google
    </Button>
  );
}

GoogleLoginButton.propTypes = {
  onUserLoggedIn: func.isRequired,
  onFailure: func.isRequired
};