import React, { useState } from 'react';
import { func } from 'prop-types';
import { Base64 } from 'js-base64';
import axios from 'axios';

import FormLogin from './FormLogin';


const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const isEmailFormatValid = email => email.match(emailRegExp);
const isEmailFormatInvalid = email => !email.match(emailRegExp);

const areRequiredFieldsNotEmpty = (email, password) => !email || !password;
const isValidationErrored = err => Boolean(err);


function FormLoginContainer({ onFailure, onUserLoggedIn }) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailValidationError, setEmailValidationError] = useState('');

  const validateEmailField = event => {
    if (isEmailFormatInvalid(event.target.value)) {
      setEmailValidationError('Invalid email format: example@domain.com');
    }
  };

  const handleInputChange = event => {
    const { name, value } = event.target;

    if (emailValidationError && name === 'email' && isEmailFormatValid(value)) {
      setEmailValidationError('');
    }
    if (name === 'email') {
      setEmail(value);
    }
    if (name === 'password') {
      setPassword(value);
    }
  };

  const handleFormSubmit = event => {
    const requestUrl = `/api/auth/form/login`;

    const authPayload = Base64.encode(`${email}:${password}`);

    axios
      .post(requestUrl,
        { credentials: authPayload },
        {
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          withCredentials: true
        })
      .then(() => {
        onUserLoggedIn();
      })
      .catch(error => {
        onFailure(error);
      });

    event.preventDefault();
  };

  const isSubmitDisabled = areRequiredFieldsNotEmpty(email, password) || isValidationErrored(emailValidationError);

  return (
    <FormLogin handleFormSubmit={handleFormSubmit}
               handleInputChange={handleInputChange}
               handleOnBlur={validateEmailField}
               emailValidationError={emailValidationError}
               isSubmitDisabled={isSubmitDisabled}
    />
  );

}

FormLoginContainer.propTypes = {
  onFailure: func.isRequired,
  onUserLoggedIn: func.isRequired,
};

export default FormLoginContainer;

