import React, { useState } from 'react';
import { func } from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  CssBaseline,
  Grid,
  Link,
  Paper,
  Typography
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { GoogleLoginButton } from '../GoogleLoginButton';
import { FormLogin } from '../FormLogin';
import { GOOGLE_CLIENT_ID } from '../../utils/constants';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://intelliarts.com/">
        Intelliarts
      </Link>
      {` 1999-${new Date().getFullYear()}`}
    </Typography>
  );
}

const styles = {
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url("./background.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme => theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  avatar: {
    margin: 1,
    backgroundColor: theme => theme.palette.secondary.main,
  },
  googleButtonContainer: {
    margin: theme => theme.spacing(6, 0, 3, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  accordion: {
    mt: 8,
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'column'
  },
  accordionSummary: {
    alignItems: 'center',
  },
  accordionPrimaryText: {
    width: '60%',
    flexShrink: 0,
    fontWeight: 200
  },
  accordionSecondaryText: {
    color: 'text.secondary',
    fontWeight: 100,
    opacity: 0,
    '&:hover': {
      opacity: 'inherit',
    }
  },
};

export default function SignInPage(props) {
  const { onFailure, onUserLoggedIn, onForgotPassword } = props;

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(prevValue => !prevValue);

  return (
    <Grid container component="main" sx={styles.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} sx={styles.image} />

      <Grid item xs={12} sm={8} md={5} component={Paper} sx={styles.paperContainer} elevation={6} square>
        <Box sx={styles.paper}>
          <Avatar sx={styles.avatar}><LockOutlinedIcon /></Avatar>

          <Typography component="h1" variant="h5">
            Sign in with
          </Typography>

          <Grid id="google-login-root-container" container sx={styles.googleButtonContainer}><Grid item xs>

            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleLoginButton
                onUserLoggedIn={onUserLoggedIn}
                onFailure={onFailure} />
            </GoogleOAuthProvider>

          </Grid></Grid>

          <Accordion id="form-login-accordion"
                     sx={styles.accordion} expanded={expanded} onChange={toggleExpanded} elevation={0} square>
            <AccordionSummary id="form-login-accordion-summary" expandIcon={<ExpandMoreIcon />}
                              sx={styles.accordionSummary}>
              <Typography sx={styles.accordionPrimaryText}>No Google account? </Typography>
              <Typography sx={styles.accordionSecondaryText}>or mb U`r a Bot?</Typography>
            </AccordionSummary>

            <AccordionDetails id="form-login-accordion-details">
              <FormLogin
                onUserLoggedIn={onUserLoggedIn}
                onFailure={onFailure} />

              <Grid container><Grid item xs>
                <Link id="forgot-password-link" href="#" variant="body2"
                      onClick={onForgotPassword}>
                  Forgot password?
                </Link>
              </Grid></Grid>
            </AccordionDetails>

          </Accordion>
        </Box>

        <Box mt={5}>
          <Copyright />
        </Box>
      </Grid>

    </Grid>
  );
}

SignInPage.propTypes = {
  onForgotPassword: func.isRequired,
  onUserLoggedIn: func.isRequired,
  onFailure: func.isRequired,
};
