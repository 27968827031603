import React from 'react';
import { func, shape } from 'prop-types';
import { CookiesProvider, withCookies } from 'react-cookie';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';

import { theme } from '@intelliarts/react-components';

import { SignInPage } from './components/SignInPage';

import { DOMAIN, REDIRECT_URL } from './utils/constants';

import 'react-toastify/dist/ReactToastify.css';


function LoginApp({ cookies }) {
  const onUserLoggedIn = () => {
    const redirectUrl = cookies.get('originalUrl') || REDIRECT_URL;

    cookies.remove('originalUrl', { domain: DOMAIN });

    window.location.replace(redirectUrl);
  };

  const onFailure = err => {
    err.details && console.error(err.details);

    if (err.response && err.response.status === 403) {
      toast.error('Invalid Credentials!', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else {
      toast.error('Something went wrong!', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <SignInPage
          onUserLoggedIn={onUserLoggedIn}
          onFailure={onFailure}
        />
        <ToastContainer />
      </CookiesProvider>
    </ThemeProvider>
  );
}

LoginApp.propTypes = {
  cookies: shape({
    get: func.isRequired,
    remove: func.isRequired,
  }).isRequired,
};

export default withCookies(LoginApp);
