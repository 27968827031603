import React from 'react';
import { bool, func, string } from 'prop-types';
import { Box, Button, TextField } from '@mui/material';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  submitButton: {
    m: theme => theme.spacing(3, 0, 2)
  }
};

export default function FormLogin(props) {
  const {
    handleFormSubmit,
    handleInputChange,
    handleOnBlur,
    emailValidationError: emailErrorMessage,
    isSubmitDisabled
  } = props;

  return (
    <Box component={'form'} sx={styles.form}
         onSubmit={handleFormSubmit}
    >
      <TextField variant="outlined"
                 margin="normal"
                 required
                 fullWidth
                 id="email"
                 label="Email Address"
                 name="email"
                 autoComplete="email"
                 autoFocus
                 onChange={handleInputChange}
                 error={Boolean(emailErrorMessage)}
                 helperText={emailErrorMessage}
                 onBlur={handleOnBlur}
      />
      <TextField variant="outlined"
                 margin="normal"
                 required
                 fullWidth
                 name="password"
                 label="Password"
                 type="password"
                 id="password"
                 autoComplete="current-password"
                 onChange={handleInputChange}
      />
      <Button type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={styles.submitButton}
              disabled={isSubmitDisabled}
      >
        Sign In
      </Button>
    </Box>
  );
}

FormLogin.propTypes = {
  handleFormSubmit: func.isRequired,
  handleInputChange: func.isRequired,
  handleOnBlur: func.isRequired,
  emailValidationError: string,
  isSubmitDisabled: bool
};
