import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';

export const getGoogleLogin = (onFailure, onUserLoggedIn) => {
  const login = googleAuthorizationCode => {
    const requestUrl = `/api/auth/google/login?redirect_url=${generateUrlRedirect(window.location)}`;

    axios.post(requestUrl, googleAuthorizationCode, {
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      withCredentials: true
    })
      .then(onUserLoggedIn)
      .catch(onFailure)
  };

  return useGoogleLogin({
    flow: 'auth-code',
    onSuccess: login,
    onError: onFailure
  });
};

const generateUrlRedirect = location => {
  return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
};